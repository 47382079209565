





























import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ImageSourceSet } from 'client-website-ts-library/types';

@Component({
  components: {
  },
})
export default class NinetyDayFreeTrial extends Mixins(View) {
  public image: ImageSourceSet = { source: '/assets/images/photos/investors-support', formats: ['webp', 'jpg'], fallback: 'jpg' };
}
